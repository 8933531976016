<div *ngIf="!sysService.isPermission()">
  <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
  <p-toolbar>
    <div class="p-toolbar-group-start">
      <f-nav first="/studyDescription" label="Study Descriptions" [model]="studyDescription"> </f-nav>

    </div>
    <div class="p-toolbar-group-end">
      <button pButton pRipple type="button" label="Delete" icon="pi pi-trash" class="p-button-outlined
      p-button-outlined p-button-danger" (click)="deleteStudyDescription(studyDescription.id)"
        *ngIf="studyDescription.isEdit()"></button>
    </div>
  </p-toolbar>

  <form [formGroup]="studyDescription.form">
    <div *ngIf="studyDescription.isform || studyDescription.isView()" class="card">
      <div class="grid fluid">

        <div class="field col-12 md:col-12 lg:col-6">
          <label for="countryId">Modality <span class="validation-star">*</span></label>
          <p-autoComplete forceSelection="true" formControlName="modalityId" [suggestions]="modalityLookup"
            [autofocus]="true" (completeMethod)="filterModality($event)" field="code" ariaLabel="Modality"
            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="7">
          </p-autoComplete>
          <f-error [err]="studyDescription.required('modalityId')" msg="Modality is required"></f-error>

        </div>
        <div class="field col-12 md:col-12 lg:col-6">
          <label for="name">Study Description <span class="validation-star">*</span></label>
          <input type="text" pInputText id="name" minlength="3" maxlength="30" formControlName="name" required pAutoFocus
            autocomplete="off" />
          <f-error [err]="studyDescription.required('name')" msg="Study Description is required"></f-error>
          <f-error [err]="studyDescription.minlength('name')" msg="Study Description must be at least 3 characters"></f-error>
          <f-error [err]="studyDescription.pattern('name')"    msg="Study Description must not contain + character"></f-error>
        </div>
        <div class="field col-12 md:col-6 lg:col-6">
          <label>Status</label>
          <p-dropdown formControlName="status" inputId="status" placeholder="Select" [options]="staticService.status"
            required="true">
          </p-dropdown>
          <!-- <f-error [err]="studyDescription.required('status')" msg="Status  is required"></f-error> -->

        </div>


      </div>
      <div class="formgrid text-center bc">
        <button pButton pRipple type="button" label="Cancel" icon="pi pi-times" class="p-button-outlined p-button-info"
          (click)="listView(studyDescription.isEmptyList());"></button>
        <button pButton pRipple type="button" [label]="studyDescription.isEdit() ? 'Update' : 'Save'" icon="pi pi-check"
          class="p-button-raised p-button-success" (click)="saveStudyDescription()" [disabled]="sysService.loading"
          *ngIf="studyDescription.isForm()">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
        <button pButton pRipple type="button" label=" Save & Add" icon="pi pi-plus"
          class="p-button-raised p-button-info" (click)="saveStudyDescription(true)" [disabled]="sysService.loading"
          *ngIf="studyDescription.isNew()">
          <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
        </button>
      </div>
    </div>
  </form>

  <div class="table-responsive" [style.display]="studyDescription.islist? 'block':'none'">
    <p-table #dt [value]="studyDescription.list" [totalRecords]="studyDescription.total" [lazy]="true"
      selectionMode="multiple" [scrollable]="true" scrollHeight="65vh" (onLazyLoad)="listStudyDescriptionLazy($event)"
      dataKey="id" [(selection)]="studyDescription.selection" [loading]="sysService.loading" filterDelay="400"
      currentPageReportTemplate="{first} to {last} of {totalRecords}" [rowsPerPageOptions]="[25,50,]" [rowHover]="true"
      styleClass="p-datatable-sm" [rows]="25" [paginator]="true" class="study-list-class" [scrollHeight]="heightTable"
      [showCurrentPageReport]="true" alwaysShow="false" paginatorPosition="bottom"
      styleClass="p-datatable-sm p-datatable-gridlines"
      [globalFilterFields]="['name','code','taxCode','countryId.name']">

      <ng-template pTemplate="caption">
        <div class="grid">
          <div class="col-6 md:col-4 lg:col-2">
            <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input pInputText type="text"  maxlength="150" [(ngModel)]="studyDescription.searchTxt"
                (input)="dt.filterGlobal($event.target.value, 'contains')" placeholder="Search..." />
            </span>
          </div>
          <div class="col-6 md:col-4 lg:col-10 text-left">
            <span>

              <button pButton pRipple type="button" label="Add Study Description" icon="pi pi-plus"
                class="p-button-raised" *ngIf="studyDescription.isList()" (click)="add()"></button>
            </span>
          </div>
          <!-- <div class="col-4 md:col-4 lg:col-6 text-right">
            <t-export [header]="studyDescriptionExport" [rows]="studyDescription.list" file="studyDescription"></t-export>
          </div> -->
        </div>
      </ng-template>

      <ng-template pTemplate="paginatorleft">
        <button pButton pRipple type="button" label="Delete" icon="pi pi-trash"
          class="p-button-outlined p-button-danger" (click)="deleteStudyDescriptions()"
          *ngIf="!studyDescription.isEmptyList() && studyDescription.selection.length > 0"></button>

      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>

          <th pSortableColumn="modalityId.code">Modality<p-sortIcon field="modalityId.code"></p-sortIcon>
          </th>
          <th pSortableColumn="name" style="min-width:117px;">Study Description <p-sortIcon field="name"></p-sortIcon>
          </th>
          <th pSortableColumn="createdBy.name">Created By<p-sortIcon field="createdBy.name"></p-sortIcon>
          </th>

          <th pSortableColumn="status">Status<p-sortIcon field="status"></p-sortIcon>
          </th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-studyDescription>
        <tr [ngClass]="{'ui-state-highlight': chk.checked}">
          <td>
            <p-tableCheckbox #chk [value]="studyDescription"></p-tableCheckbox>
          </td>

          <td class="bdy-part"><i class="pi pi-file-edit link-study" (click)="edit(studyDescription.id)"
              title="edit"></i>{{studyDescription.modalityId?.code}}</td>
          <td>
            &nbsp;{{studyDescription.name }}</td>
          <td>{{studyDescription?.createdByName}}</td>
          <td *ngIf="studyDescription.status ===0" class="text-danger">Inactive</td>
          <td *ngIf="studyDescription.status ===1" class="text-success">Active</td>

        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td class="norecordstudy" [attr.colspan]="5">No records found</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>