<div class="table-responsive">
    <form [formGroup]="userSetting.form">
        <div class="grid">
            <div class="col-12 md:col-6 lg:col-6">
                <h2 class="text-center">Study List Settings</h2>
                <div class="grid">
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        Show Creation Date
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        <div>
                            <p-inputSwitch formControlName="showCreatedDate"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        <div class="mr-4">
                            Show Patient Id
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        <div>
                            <p-inputSwitch formControlName="showPatId"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center ">
                        <div class="mr-3">
                            Show Patient Sex
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        <div>
                            <p-inputSwitch formControlName="showPatSex"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center ">
                        <div class="mr-8">
                            &nbsp; Show S/I
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        <div>
                            <p-inputSwitch formControlName="showSeriesInstanceCount"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center ">
                        <div class="mr-6">
                            Show Status
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center">
                        <div>
                            <p-inputSwitch formControlName="showStatus"></p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="!sysService.isRadSub()" class="col-12 md:col-6 lg:col-6">
                <h2 class="text-center" *ngIf=" sysService.isCenter() || sysService.isRad()  ">Report
                    Settings</h2>
                <div class="grid">
                    <div class="col-6 md:col-6 lg:col-6 text-center mt-3 "
                    *ngIf=" sysService.isCenter() || sysService.isRad()  ">
                        Report Approval Rules
                    </div>
                    <div class="col-6 md:col-6 lg:col-6"
                    *ngIf=" sysService.isCenter() || sysService.isRad()  ">
                        <p-dropdown class=" drop" formControlName="showAutoApproval" inputId="approvalRule"
                            placeholder="Select" [options]="staticService.approvalRule">
                        </p-dropdown>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center mt-3 " *ngIf="sysService.isAdmin()">
                        Bonus
                    </div>
                    <div class="col-6 md:col-6 lg:col-6" *ngIf="sysService.isAdmin()">
                        <p-inputNumber inputId="integeronly" class=" drop" formControlName="bonus" maxlength="5"
                            placeholder="Amount">
                        </p-inputNumber>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center mt-3 " *ngIf="sysService.isRad()">
                        <div class="mr-4">
                            Editable By Admin
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6  " *ngIf="sysService.isRad()">
                        <p-dropdown class="drop" (onChange)="oncahngeAlert($event)" formControlName="editableByAdmin"
                            inputId="status" placeholder="Select" [options]="staticService.makeItEditable">
                        </p-dropdown>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center " *ngIf="sysService.isRad()">
                        <div class="ml-5">
                            Allow Document Download
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6" *ngIf="sysService.isRad()">
                        <div>
                            <p-inputSwitch formControlName="downloadDoc"></p-inputSwitch>
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-6 text-center mt-3 " *ngIf="sysService.isRad()">
                        <div class="mr-8">
                            Disclaimer
                        </div>
                    </div>
                    <div class="col-6 md:col-6 lg:col-5 " *ngIf="sysService.isRad()">
                        <textarea [rows]="6" [cols]="30" maxlength="225"
                            class="p-inputtextarea p-inputtext p-component p-element" (onclick)="checkDisclaimer($event)" formControlName="disclaimer"
                            pInputTextarea></textarea>
                        <p-checkbox class="pt-2" (onChange)="toggleCheckboxValue($event)"
                            formControlName="defaultDisclaimer" [binary]="true" inputId="binary">
                        </p-checkbox>
                        &nbsp; Use default disclaimer for reports.

                    </div>
                </div>

            </div>

            <div class="col-12 text-center">

                <div class="field mt-6">
                    <button pButton pRipple type="button" (click)="userSettings()" label=" Update" icon="pi pi-check"
                        class="p-button-success" [disabled]="sysService.loading">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>

                    </button>

                </div>


            </div>
        </div>



    </form>
    <div class="col-12 text-right ">
        <label id="note-label">(<b>Note:</b>Changes in user settings will take effect only after re-login.)
        </label>
    </div>
</div>