import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormDefault, Model, StaticService, SysService, View, ViewFactory } from '@app/_common';
@Component({
  selector: 'app-turn-around-time',
  templateUrl: './turn-around-time.component.html',
  styleUrls: ['./turn-around-time.component.css'],
  providers: [DatePipe]
})
export class TurnAroundTimeComponent implements OnInit {
  @ViewChild('calendarRef') calendar: any;
  TurnAroundTimeExport = ['name', 'taxCode', 'code', 'countryId.name']; // for export header fields
  private TurnAroundTimeView: View = this.viewFactory.newView();
  modalityLookup: any[];
  radLookup: any[];
  cenLookup: any[];

  modalityDataAxis: any;
  radilogistaxis: any;
  rejectedStudies: any;
  rejectedStudiesAxis: any;
  revenueDataAxis: any;
  revenueData: any;
  tatData: any;
  totalRevenueData: any;
  tatDataAxis: any;
  allStudiesData: any;
  allStudiesDataAxies: any;
  calendarSelected = false;
  currentDate: Date;
  selectedRange: Date[];
  data:any;
  options:any;
  TATvisible:boolean = false;
  filterByStudyStatusCode: any = 1;
  constructor(
    public sysService: SysService,
    private formBuilder: FormBuilder,
    public route: ActivatedRoute,
    public staticService: StaticService,
    private viewFactory: ViewFactory,
    private datePipe: DatePipe
  ) { }
  ngOnInit() {
    this.TurnAroundTimeView.of(this.TurnAroundTimeModel());
    this.TurnAroundTimeData();
    this.filterMod(Event)
    this.filterRad(Event)
    this.filterCen(Event)
  }
  get TurnAroundTime() {
    return this.TurnAroundTimeView.model;
  }
  private TurnAroundTimeModel() {
    const formDefault = new FormDefault(["studyIuid"], []);
    return new Model(
      this.formBuilder.group({
        id: [],
        modality: [],
        radId: [],
        cenId: [],
        date: ['']
      }),
      this.sysService,
      formDefault,
      ""
    );
  }
  turnTime() {
    const createdDate = String(this.TurnAroundTime.get("date"));
    const startDate = new Date(createdDate.split(",")[0]);
    const endDate = new Date(createdDate.split(",")[1]);
    const formattedStartDate = `${startDate.getDate().toString().padStart(2, '0')}/${(startDate.getMonth() +
      1).toString().padStart(2, '0')}/${startDate.getFullYear()}`;
    const formattedEndDate = `${endDate.getDate().toString().padStart(2, '0')}/${(endDate.getMonth() +
      1).toString().padStart(2, '0')}/${endDate.getFullYear()}`;
    const rangeDate = `${formattedStartDate}-${formattedEndDate}`;
    if (!this.TurnAroundTime.isEmpty("date")) {
      this.TurnAroundTimeView.setParam("date", rangeDate);
    }
    this.TurnAroundTimeView.setParam("radId", this.TurnAroundTime.get("radId"))
    this.TurnAroundTimeView.setParam("modality", this.TurnAroundTime.get("modality"))
    this.TurnAroundTimeView.setParam("cenId", this.TurnAroundTime.get("cenId"))
    this.TurnAroundTimeData()

  }
  filterMod(event: any) {
    this.TurnAroundTimeView.getData(`/modality/modality-lookup?filter=`).then(data => {
      this.modalityLookup =
        data;
    });
  }
  filterRad(event: any) {
    this.TurnAroundTimeView.getData(`/radiologist/radiologist-lookup?filter=`).then(data => {
      this.radLookup =
        data;
    });
  }
  filterCen(event: any) {
    this.TurnAroundTimeView.getData(`/centre/centre-lookup?filter=`).then(data => {
      this.cenLookup =
        data;
    });
  }

  reset() {
    this.TurnAroundTime.reset()
    this.TurnAroundTimeData()
  }

  TurnAroundTimeData() {

    this.TurnAroundTimeView.getData('/turnaroundtime/studies-tat').then(res => {
      this.allStudiesData = {
        labels: res.month, // Assuming res.month contains an array of months
        datasets: [
          {
            label: 'Studies Per Month', // Changing the label to represent the Y-axis
            data: res.studiesPerMonth, // Assuming res.studiesPerMonth contains the studies per month data
            backgroundColor: '#BF7C1A',
            color: '#fff'
          },
          {
            label: 'Completed Studies Per Month',
            backgroundColor: '#40e82a',
            data: res.completedStudiesPerMonth
        }
        ]
      };

      this.allStudiesDataAxies = {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month',
              color: '#fff',
              style: {
                color: '#fff'
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Studies Per Month',
              color: '#fff',
              style: {
                color: '#fff'
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          }
        },
        onClick: (event, elements) => {
          console.log(elements)
          if (elements.length > 0) {
            const elementIndex = elements[0].index;
            const clickedMonth = res.month[elementIndex];
            const studiesPerMonth = res.studiesPerMonth[elementIndex];
            this.handleMonthClick(clickedMonth, studiesPerMonth);
          }
        }
      };
    });

    this.TurnAroundTimeView.getData('/turnaroundtime/assign-complete-tat').then(res => {

      this.tatData = {
        labels: res.month,
        datasets: [
          {
            label: 'Average Hour',
            data: res.averageHour,
            backgroundColor: '#BF7C1A',
            color: '#fff'
          }
        ]
      };

      this.tatDataAxis = {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month',
              color: '#fff',
              style: {
                color: '#fff'
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Average Hour',
              color: '#fff',
              style: {
                color: '#fff'
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          }
        }
      };
    });

    if (!this.sysService.isCenter()) {
      this.TurnAroundTimeView.getData('/turnaroundtime/revenue-tat').then(res => {
        this.totalRevenueData = res.totalRevenue
        this.revenueData = {
          labels: res.month,
          datasets: [
            {
              label: 'Revenue Per Month',
              data: res.revenue,
              backgroundColor: '#BF7C1A',
              color: '#fff'
            }
          ]
        };

        this.revenueDataAxis = {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Month',
                color: '#fff',
                style: {
                  color: '#fff'
                }
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Revenue',
                color: '#fff',
                style: {
                  color: '#fff'
                }
              },
              grid: {
                color: 'rgba(0, 0, 0, 0.1)'
              }
            }
          }
        };
      });
    }

    this.TurnAroundTimeView.getData('/turnaroundtime/rejected-studies-tat').then(res => {

      this.rejectedStudies = {
        labels: res.month,
        datasets: [
          {
            label: 'Studies Per Month',
            data: res.studiesPerMonth,
            backgroundColor: '#BF7C1A',
            color: '#fff'
          }
        ]
      };
      this.rejectedStudiesAxis = {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Month',
              color: '#fff',
              style: {
                color: '#fff'
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Studies Per Month',
              color: '#fff',
              style: {
                color: '#fff'
              }
            },
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            }
          }
        },
        
        onClick: (event, elements) => {
          if (elements.length > 0) {
            const elementIndex = elements[0].index;
            const clickedMonth = res.month[elementIndex];
            const studiesPerMonth = res.studiesPerMonth[elementIndex];
            this.handleMonthClick(clickedMonth, studiesPerMonth);
          }
        }
      };
    });
  }
 
 
  handleMonthClick(month: string, studies: number): void {
    console.log(`You clicked on ${month}. Studies in this month: ${studies}`);

    this.TATvisible = true;
    this.chartfun()
  }
 
  chartfun() {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');

    this.data = {
      labels: ['A', 'B', 'C'],
      datasets: [
        {
          data: [540, 325, 702],
          backgroundColor: [documentStyle.getPropertyValue('--blue-500'), documentStyle.getPropertyValue('--yellow-500'), documentStyle.getPropertyValue('--green-500')],
          hoverBackgroundColor: [documentStyle.getPropertyValue('--blue-400'), documentStyle.getPropertyValue('--yellow-400'), documentStyle.getPropertyValue('--green-400')]
        }
      ]
    };

    this.options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            color: textColor
          }
        }
      }
    };
  }
}