<div *ngIf="!sysService.isPermission()">
    <not-found></not-found>
</div>
<div *ngIf="sysService.isPermission()">
    <div class="table-responsive pl-2">
        <form [formGroup]="TurnAroundTime.form">
            <div class="grid ">
                <div class="col-12 md-col-6 lg:col-2 ">
                    <p-multiSelect [options]="modalityLookup" optionLabel="name" formControlName="modality"
                        (onLazyLoad)="filterMod($event)" optionValue="name" placeholder="Modality">
                        <ng-template let-option pTemplate="item">
                            <span style="width: 150px" class="ml-1">{{ option.name }}</span>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="col-12 md-col-6 lg:col-2">
                    <p-multiSelect [options]="radLookup" optionLabel="name" formControlName="radId" optionValue="id"
                        placeholder="Radiologist">
                        <ng-template let-option pTemplate="item">
                            <span style="width: 150px" class="ml-1">{{ option.name }}</span>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="col-12 md-col-6 lg:col-2" *ngIf="!sysService.isCenter()">
                    <p-multiSelect [options]="cenLookup" optionLabel="name" formControlName="cenId"
                        (onLazyLoad)="filterCen($event)" optionValue="id" placeholder="Centre">
                        <ng-template let-option pTemplate="item">
                            <span style="width: 150px" class="ml-1">{{ option.name }}</span>
                        </ng-template>
                    </p-multiSelect>
                </div>
                <div class="col-12 md-col-6 lg:col-2 mt-2">
                    <p-calendar #calendarRef [showIcon]="false" (onSelect)="calendarSelected = true"
                        placeholder="DD/MM/YYYY-DD/MM/YYYY" formControlName="date" dateFormat="dd/mm/yy"
                        [numberOfMonths]="1" [maxDate]="currentDate" class="widthClass daterange calinput"
                        selectionMode="range" [readonlyInput]="true" inputId="range" [(ngModel)]="selectedRange">
                    </p-calendar>
                </div>
                <div class="ml-2 mt-3">
                    <button pButton pRipple type="button" label="Search" (click)="turnTime()"
                        class="p-button-raised p-button-info" [disabled]="sysService.loading"> <i
                            *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>

                    <button pButton pRipple type="button" label="Reset" icon=" pi pi-undo" (click)="reset()"
                        class="p-button-raised p-button-text" [disabled]="sysService.loading"> <i
                            *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i></button>
                </div>
            </div>
        </form>
    </div>
    <div class="graph-wrap ">
        <div class="graph-child">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>ALL STUDIES</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="allStudiesData" [options]="allStudiesDataAxies"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
        <div class="graph-child ">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>AVERAGE TURN AROUND TIME</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="tatData" [options]="tatDataAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
    </div>
    <div class="graph-wrap ">

        <div class="graph-child " *ngIf="sysService.isRad()">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>TOTAL REVENUE</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="revenueData" [options]="revenueDataAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
        <div class="graph-child ">
            <div class="card mb-2 mt-5 ">
                <div class="ml-2">
                    <h3>REJECTED STUDIES</h3>
                </div>
                <p-chart type="bar" responsive="true" [data]="rejectedStudies" [options]="rejectedStudiesAxis"
                    style="height: 50%;width: 100%;max-width: 800px;">
                </p-chart>
            </div>
        </div>
    </div>
</div>

<p-dialog header="REJECTED STUDIES PER MONTH" [(visible)]="TATvisible" [modal]="true" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
    [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">

    <div class="card flex justify-content-center ">
        <p-chart type="pie" responsive="true" [data]="data" [options]="options"
            style="height: 50%;width: 100%;max-width: 800px;">
        </p-chart>

    </div>


</p-dialog>