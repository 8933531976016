export class StaticData {
    static boroughStatus = [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
    ];
    static priorityStatus = [
        { label: 'High', value: 1 },
        { label: 'Low', value: 2 },
        { label: 'Medium', value: 3 },
    ];
    static studyFilter = [
        { label: 'Today', value: 0 },
        { label: 'Yesterday', value: 1 },
        { label: 'Last 2 Days', value: 2 },
        { label: 'Last 7 Days', value: 7 },
        { label: 'Last 30 Days', value: 30 },
    ];

    static progresStatus = [
        { label: 'Completed', value: 1 },
        { label: 'Failed', value: 2 },
        { label: 'Ongoing', value: 3 },
        { label: 'Pending', value: 4 },
        { label: 'Rescheduled', value: 5 },
    ];
    static subjectTicket = [
        { label: 'Application related', value: '0' },
        { label: 'Editor related', value: '1' },
        { label: 'Payment related', value: '3' },
        { label: 'Viewer related', value: '4' },
        { label: 'Wallet related', value: '5' },
        { label: 'Others', value: '2' },

    ]
    static hisStatus = [
        { label: 'All', value: '0' },
        { label: 'Arrived', value: '7' },
        { label: 'Completed', value: '9' },
        { label: 'Cancelled', value: '12' },
        { label: 'Discontinued', value: '10' },
        { label: 'In Progress', value: '8' },
        { label: 'Ordered', value: '3' },
        { label: 'Registered', value: '5' },
        { label: 'Reported', value: '11' },
        { label: 'Sent For Approvel', value: '4' },
        { label: 'Scheduled', value: '6' },



    ]
    static contactRoles = [
        { label: 'Centre', value: 'Centre' },
        { label: 'Physician', value: 'Physician' },
        { label: 'Radiologist', value: 'Radiologist' },
        { label: 'Technician', value: 'Technician' },
        { label: 'Assigner', value: 'Assigner' },
        { label: 'Approver', value: 'Approver' },
        { label: 'Teleradiologist', value: 'Teleradiologist' },

    ]
    static contactRoles1 = [
        { label: 'Centre', value: 'Centre' },
        { label: 'Radiologist', value: 'Radiologist' },
        { label: 'Teleradiologist', value: 'Teleradiologist' },


    ]
    static contactRoles2 = [
        { label: 'Physician', value: 'Physician' },
        { label: 'Radiologist', value: 'Radiologist' },

        { label: 'Technician', value: 'Technician' },

        { label: 'Teleradiologist', value: 'Teleradiologist' },


    ]
    static bonusType = [
        { label: 'Custom Bonus', value: 2 },
        { label: 'Joining Bonus', value: 1 },
        { label: 'Wallet Deduction', value: 3 },
    ]
    static contactStatus = [
        { label: 'Active', value: 2 },
        { label: 'Blocked', value: 0 },
        { label: 'Pending', value: 1 },
        { label: 'Requested', value: 3 },

    ]
    static contactStatus1 = [
        { label: 'Active', value: 2 },
        { label: 'Blocked', value: 0 },


    ]
    static status = [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 },
    ];
    static statusRadi = [
        { label: 'Active', value: 1 },
        { label: 'Approve Pending', value: 2 },
        { label: 'Inactive', value: 0 },
    ];
    static reportType = [
        { label: 'Report Date-Time', value: true },
        { label: 'Uploaded Time', value: false },
    ];
    static rejectedBy = [
        { label: 'Centre', value: 'Centre' },
        { label: 'Radiologist', value: 'Radiologist' },
    ];
    static teleradiologyCoordinator = [
        { label: 'Radiologist', value: false },
        { label: 'TeleradiologyCoordinator', value: true },
    ]
    static paymentStatus = [
        { label: 'Paid', value: 1 },
        { label: 'Not Paid', value: 0 },
    ];
    static ticketStatus = [
        { label: 'Active', value: 1 },
        { label: 'Closed', value: 0 },
    ];
    static month = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ];
    static year = [
        { label: '2023', value: 2023 },
        { label: '2022', value: 2022 },
        { label: '2021', value: 2021 },
        { label: '2019', value: 2023 },
    ];
    static assignmentRule = [
        { label: 'Studies are visible to all group members', value: 1 },
        { label: 'Studies are manually assigned to a member', value: 2 },
    ];
    static approvalRule = [
        { label: 'Auto Approval', value: 1 },
        { label: 'Manual Approval', value: 2 },
    ];
    static makeItEditable = [
        { label: 'Editable By Centre', value: 1 },
        { label: 'Editable By Primary Radiologist', value: 2 },
        { label: 'Both', value: 3 },
        { label: 'None', value: 0 },
    ]
    static visibilityRule = [
        { label: 'All', value: 1 },
        { label: 'Group Admin', value: 2 },
    ];
    static sharingScope = [
        { label: 'Shareable', value: 1 },
        { label: 'Non-Shareable', value: 0 },
    ];
    static userRole = [
        { label: 'Approver', value: 2 },
        { label: 'Assigner', value: 1 },
    ];
    static centerUser = [
        { label: 'Physician', value: 4 },
        { label: 'Technician', value: 3 },
    ];
    static hideCentreName = [
        { label: 'Hide', value: 1 },
        { label: 'Show', value: 0 },
    ];
    static templateType = [
        { label: 'eCScribe Template', value: 1 },
        { label: 'Radiologist Template', value: 0 },
    ];
    static TransactionType = [
        { label: 'Recharge', value: 1 },
        { label: 'Refund', value: 2 },
    ];
    static payStatus = [
        { label: 'Cancel', value: 0 },
        { label: 'Failed', value: 2 },
        { label: 'Pending', value: 1 },
        { label: 'Success', value: 3 },
    ];
    static eClarityFee = [
        { label: 'Paid By Me', value: 1 },
        { label: 'Other', value: 0 },
    ]
    static statusStudyList = [
        { label: 'Completed', value: 4 },
        { label: 'Drafted', value: 2 },
        { label: 'Pending', value: 1 },
        { label: 'Rejected ', value: 6 },
        { label: 'Unapproved ', value: 3 },
    ]
    static statusStudyListRadi = [
        { label: 'Completed', value: 1 },
        { label: 'Draft', value: 2 },
        { label: 'Pending', value: 0 },
    ]
    static dateType = [
        { label: 'Created date', value: 1 },
        { label: 'Study Date', value: 2 },
    ]
    static rejectReason = [
        { label: 'Image not clear', value: 1 },
        { label: 'Image incomplete', value: 2 },
        { label: 'Difficult', value: 3 },
        { label: 'Other', value: 4 },
    ]
    static roleType = [
        { label: 'Centre', id: '3' },
        { label: 'Radiologist', id: '2' },
    ]
    static activeStatus = [
        { label: 'Active', value: 1 },
        { label: 'Inactive', value: 0 },
    ];
    static gender = [
        { label: 'Not Mentioned', value: '*' },
        { label: 'Male', value: 'M' },
        { label: 'Female', value: 'F' },


    ];
    static time = [
        { label: '6:00 AM - 9:00 AM', value: 1 },
        { label: '9:00 AM - 12:00 PM', value: 2 },
        { label: '12:00 PM - 3:00 PM', value: 3 },
        { label: '3:00 PM - 6:00 PM', value: 4 },
        { label: '6:00 PM - 9:00 PM', value: 5 },
        { label: '9:00 PM - 12:00 AM ', value: 6 },
    ];
    static groupset = [
        { label: 'Auto  Aassign', value: 'autoassign' },
        // { label: 'Hierarchy', value: 'Hierarchy' },
        // { label: 'Study discription', value: 'Study discription' }
    ];
    static grouphrs = [
        { label: '1 hrs', value: '1' },
        { label: '2 hrs', value: '2' },
        { label: '3 hrs', value: '3' },
        { label: '4 hrs', value: '4' },
        { label: '5 hrs', value: '5' },
        { label: '6 hrs', value: '6' },
        { label: '7 hrs', value: '7' },
        { label: '8 hrs', value: '8' },
        { label: '9 hrs', value: '9' },
        { label: '10 hrs', value: '10' },
        { label: '11 hrs', value: '11' },
        { label: '12 hrs', value: '12' },
        { label: '13 hrs', value: '13' },
        { label: '14 hrs', value: '14' },
        { label: '15 hrs', value: '15' },
        { label: '16 hrs', value: '16' },
        { label: '17 hrs', value: '17' },
        { label: '18 hrs', value: '18' },
        { label: '19 hrs', value: '19' },
        { label: '20 hrs', value: '20' },
        { label: '21 hrs', value: '21' },
        { label: '22 hrs', value: '22' },
        { label: '23 hrs', value: '23' },
        { label: '24 hrs', value: '24' },
    ]
}