<div class="card grayCard p-4">
    <form [formGroup]="receivable.form" class="fullform">
        <div class="grid">
            <div class=" field col-12 md:col-3 lg:col-2 ">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown formControlName="month" inputId="month" placeholder="Select"
                            [options]="staticService.month" (onChange)="onDataSelectionChange($event)">
                        </p-dropdown>
                        <label for="float-input">Month </label>
                    </span>
                    <f-error [err]="receivable.required('month')" msg="Month is required"></f-error>
                </div>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown id="year" formControlName="year" inputId="year" [options]="receivableYearList"
                            placeholder="Select" optionLabel="year" optionValue="year"
                            (onChange)="onDataSelectionChange($event)">
                        </p-dropdown>
                        <label for="float-input">Year </label>
                    </span>
                    <f-error [err]="receivable.required('year')" msg="Year is required"></f-error>
                </div>
            </div>
            <div class="field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span>
                        <span class="p-float-label">
                            <p-calendar #calendarRef [showIcon]="false" dateFormat="dd/mm/yy" formControlName="date"
                                placeholder="DD/MM/YYYY-DD/MM/YYYY" [numberOfMonths]="1" class="widthClass"
                                selectionMode="range" [readonlyInput]="true" inputId="range" [(ngModel)]="selectedRange"
                                (onSelect)="onDateSelect($event)" (onSelect)="calendarSelected = true">
                                <ng-template pTemplate="footer">
                                    <div class="grid justify-content-center">
                                        <p-button label="Clear" *ngIf="calendarSelected"
                                            (click)="changesCalendarClear(dt);dt.reset()"
                                            styleClass="p-button-raised p-button-text"></p-button>&nbsp;
                                        <button pButton pRipple type="button" *ngIf="calendarSelected"
                                            (click)="calenedarChangeDate($event,dt);dt.reset()" label=" Search"
                                            class="p-button-raised p-button-text"></button>
                                    </div>
                                </ng-template></p-calendar>
                            <label for="float-input">Date Range </label>
                        </span>
                    </span>
                </div>
            </div>

            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown formControlName="isReportDate" inputId="month" placeholder="Select"
                            [options]="staticService.reportType">
                        </p-dropdown>
                        <label for="float-input">Date Types </label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="userId" field="name"
                            [suggestions]="userLookup" (completeMethod)="filteruser($event)" Label="id"
                            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Source</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="destUserId" field="name"
                            [suggestions]="destUserList" (completeMethod)="filterDestinationRadiologist($event)"
                            Label="id" [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400"
                            maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Reporting Radiologist</label>
                    </span>
                </div>
            </div>



            <div class=" field col-12 md:col-3 lg:col-2" *ngIf="!this.sysService.isRad()">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="radId" field="name"
                            [suggestions]="radLookup" (completeMethod)="filterRad($event)" Label="id" [dropdown]="true"
                            [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Radiologist</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2" *ngIf="!this.sysService. isRad()||this.sysService.isAdmin()">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="cenId" field="name"
                            [suggestions]="cenLookup" (completeMethod)="filterCen($event)" ariaLabel="bodyPart"
                            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Centre</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-autoComplete forceSelection="true" formControlName="modality" field="name"
                            [suggestions]="modLookup" (completeMethod)="filterMod($event)" ariaLabel="bodyPart"
                            [dropdown]="true" [autoHighlight]="true" showEmptyMessage="true" delay="400" maxlength="50">
                        </p-autoComplete>
                        <label for="float-input">Modality</label>
                    </span>
                </div>
            </div>
            <div class=" field col-12 md:col-3 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">
                        <p-dropdown formControlName="paymentStatus" inputId="month" placeholder="Select"
                            [options]="staticService.paymentStatus">
                        </p-dropdown>
                        <label for="float-input">Payment Status </label>
                    </span>
                </div>
            </div>
            <div class="field col-12 md:col-6 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">

                        <input id="username" placeholder="hh:mm" name="nightChargeInput" type="text" maxlength="5"
                            formControlName="FromTime" aria-describedby="username1-help" pInputText pAutoFocus
                            (input)="formatTimeInput($event)">

                        <f-error [err]="receivable.pattern('FromTime')"
                            msg="From Time  must be a valid format of HH:MM.  "></f-error>
                        <label>From </label></span>
                </div>
            </div>

            <div class="field col-12 md:col-6 lg:col-2">
                <div class="pt-3">
                    <span class="p-float-label">

                        <input id="username" placeholder="hh:mm" name="nightChargeInput" type="text" maxlength="5"
                            formControlName="toTime" aria-describedby="username1-help" pInputText pAutoFocus
                            (input)="formatTimeInput($event)">

                        <f-error [err]="receivable.pattern('toTime')"
                            msg="To time  must be a valid format of HH:MM.  "></f-error>
                        <label>To </label>
                    </span>
                </div>


            </div>
            <div class="  md:col-4">
                <div class="field py-4 ">
                    <button pButton pRipple type="button" (click)="searchreceivable();dt.reset()" label=" Search"
                        icon=" pi pi-search" class="p-button-raised p-button-info " [disabled]="sysService.loading">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>
                    <button pButton pRipple type="button" (click)="resetReceivable();dt.reset()" label=" Reset"
                        icon=" pi pi-undo" class="p-button-raised p-button-text " [disabled]="sysService.loading">
                        <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
</div>
<div class="table-responsive">
    <p-table #dt [value]="receivable.list" [scrollable]="isScrollTable" class="study-list-class"
        [scrollHeight]="heightTable" [totalRecords]="receivable.total" dataKey="id" [lazy]="true" [rows]="5"
        [(selection)]="receivable.selection" (onLazyLoad)="listRateCardListLazy($event)" [loading]="sysService.loading"
        filterDelay="400" currentPageReportTemplate="{first} to {last} of{totalRecords}" [rowsPerPageOptions]="[25, 50]"
        [rowHover]="true" styleClass="p-datatable-sm" [rows]="25" [paginator]="true" [showCurrentPageReport]="true"
        alwaysShow="false" paginatorPosition="bottom" styleClass="p-datatable-sm p-datatable-gridlines"
        [globalFilterFields]="['receivebles','centreName','patName','reportDate','modality','studyDesc','charge','paymentNote','charge']">
        <ng-template pTemplate="caption">
            <div class="flex  justify-content-between flex-wrap card-container purple-container pb-2">
                <div class="flex rec-btn-1 align-items-center justify-content-center ">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" maxlength="150" (input)="dt.filterGlobal($event.target.value,
                                          'contains')" placeholder="Search..." pAutoFocus [autofocus]="true" />
                    </span>
                    <div class="ml-2 rec-btn">
                        <button pButton pRipple type="button" label="Export PDF" icon=" pi pi-download"
                            (click)="export(1)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-warning m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button> &nbsp;
                        <button pButton pRipple type="button" label="Export Excel" icon=" pi pi-download"
                            (click)="export(2)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-secondary m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button> &nbsp;
                        <button pButton pRipple type="button" *ngIf="receivable.selection.length > 0"
                            label="Change to Paid" (click)="allStatus(receivable,1)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-success m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>&nbsp;
                        <button pButton pRipple type="button" *ngIf="receivable.selection.length > 0"
                            label="Change to Unpaid" (click)="allStatus(receivable,0)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-danger m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>

                        <button pButton pRipple type="button"
                            *ngIf=" sysService.isRad() && receivable.selection.length > 0" label="Mark as Duplicate"
                            (click)="markAsDuplicate(receivable)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-help m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>
                        <button pButton pRipple type="button"
                            *ngIf="sysService.isRad() && receivable.selection.length > 0" label="Un-Mark as Duplicate"
                            (click)="unMarkAsDuplicate(receivable)" [disabled]="sysService.loading "
                            class="p-button-raised p-button-help m-1">
                            <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
                        </button>


                    </div>
                </div>
                <div class="ml-auto pt-2"> <label class="text-2xl pt-2 "
                        *ngIf="receivable.selection.length > 0"><b>Selected Amount :</b> {{totalCharge || '0.00'}}
                    </label>
                    <label class="text-2xl pl-3 pt-2"><b>Total Amount :</b> {{totalamount || '0.00'}} </label>
                </div>

            </div>
        </ng-template>
        <ng-template pTemplate="paginatorleft">
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 3rem">
                    <p-tableHeaderCheckbox (click)="selectedValue()"></p-tableHeaderCheckbox>
                </th>
                <th pSortableColumn="recievables">Receiveble From
                    <p-sortIcon field="recievables"></p-sortIcon>
                </th>
                <th pSortableColumn="reportingRad">Reporting Radiologist
                    <p-sortIcon field="reportingRad"></p-sortIcon>
                </th>
                <th pSortableColumn="studyId">Study Id
                    <p-sortIcon field="studyId"></p-sortIcon>
                </th>
                <th pSortableColumn="patName">Patient Name
                    <p-sortIcon field="patName"></p-sortIcon>
                </th>
                <th pSortableColumn="studyCreatedDate">
                    <span *ngIf="(sysService.isRad() || sysService.isRadSub())">Assigned Time</span>
                    <span *ngIf="!sysService.isRad() && !sysService.isRadSub()">Uploaded Time</span>
                    <p-sortIcon field="studyCreatedDate"></p-sortIcon>
                </th>

                <th pSortableColumn="reportDate">Report Date-Time<p-sortIcon field="reportDate"></p-sortIcon>
                </th>
                <!-- <th pSortableColumn="receivedDateFormatted">Received Time<p-sortIcon field="receivedDateFormatted"></p-sortIcon>
                </th> -->
                <th pSortableColumn="modality">Modality<p-sortIcon field="modality"></p-sortIcon>
                </th>
                <th pSortableColumn="tat">Tat<p-sortIcon field="tat"></p-sortIcon>
                </th>
                <th pSortableColumn="studyDesc">Study Desc
                    <p-sortIcon field="studyDesc"></p-sortIcon>
                </th>
                <th pSortableColumn="studyDescModified">Study Desc Modified
                    <p-sortIcon field="studyDescModified"></p-sortIcon>
                </th>
                <th pSortableColumn="paymentNote">Note
                    <p-sortIcon field="paymentNote"></p-sortIcon>
                </th>
                <th pSortableColumn="paymentStatus">Payment Status<p-sortIcon field="paymentStatus"></p-sortIcon>
                </th>
                <th pSortableColumn="charge" style="text-align: end;">Charge<p-sortIcon field="charge"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-receivable>
            <tr [ngClass]="{'ui-state-highlight': chk.checked}">

                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    <p-tableCheckbox #chk [value]="receivable" (click)="selectedValue()"></p-tableCheckbox>
                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    {{receivable.receivebles}}
                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    {{receivable.reportingRad}}
                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    {{receivable.studyId}}
                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}" pTooltip="{{receivable?.patName}}">{{ receivable?.patName?.length > 25 ?
                    receivable?.patName?.slice(0, 25) + '...' : receivable?.patName }}</td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    <span *ngIf="(sysService.isRad() || sysService.isRadSub())"> {{ receivable.assignedDateFormatted |
                        date:'dd-MM-yyyy hh:mm a' }}</span>
                    <span *ngIf="!sysService.isRad() && !sysService.isRadSub()"> {{ receivable.studyCreatedDate |
                        date:'dd-MM-yyyy hh:mm a' }}</span>


                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">{{ receivable.reportDate | date:'dd-MM-yyyy hh:mm a' }}

                </td>
                <!-- <td [ngClass]="{
                        'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
                        'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
                        'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
                        'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
                    }">{{ receivable.receivedDateFormatted | date:'dd-MM-yyyy hh:mm a' }}
                    
                                        </td> -->
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    {{receivable?.modality}}</td>
                <td [ngClass]="{
                        'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
                        'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
                        'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
                        'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
                    }">
                    {{receivable?.tat}}</td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    {{receivable.studyDesc}}</td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}">
                    {{receivable.studyDescModified}}</td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}" pTooltip="{{receivable?.paymentNote}}">{{ receivable?.paymentNote?.length > 25 ?
                    receivable?.paymentNote?.slice(0, 25) + '...' : receivable?.paymentNote }}</td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}" class="edit-icon" *ngIf="receivable.paymentStatus == 1">
                    <i class="pi pi-file-edit link-study " pTooltip="Change status" (click)="statusChange(receivable)"
                        title="edit"></i> &nbsp; <span style="color: green;">Paid </span>
                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}" (click)="statusChange(receivable)" class="edit-icon" *ngIf="receivable.paymentStatus == 0">
                    <i class="pi pi-file-edit link-study " pTooltip="Change status" (click)="statusChange(receivable)"
                        title="edit"></i>&nbsp; <span style="color: rgb(213, 8, 8);">Not
                        Paid </span>
                </td>
                <td [ngClass]="{
    'redrow': receivable.isDuplicate && !receivable.isMarkedDuplicate && !receivable.isNightStudy,
    'marked-duplicate-row': receivable.isMarkedDuplicate && !receivable.isDuplicate && !receivable.isNightStudy,
    'marked-duplicate-gery': !receivable.isMarkedDuplicate && !receivable.isDuplicate && receivable.isNightStudy,
    'night-row': (receivable.isDuplicate && receivable.isMarkedDuplicate && !receivable.isNightStudy) || (receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (!receivable.isDuplicate && receivable.isMarkedDuplicate && receivable.isNightStudy) || (receivable.isDuplicate && !receivable.isMarkedDuplicate && receivable.isNightStudy)
}" class="link" style="text-align: end;" (click)="rateChange(receivable)">
                    <ng-container *ngIf="receivable.charge; else iconTemplate">
                        {{ receivable.charge }}
                    </ng-container>
                </td>
                <ng-template #iconTemplate>
                    <i class="pi pi-file-edit" title="add amount" title="edit"></i>
                </ng-template>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage" let-columns>
            <tr>
                <td class="norecord" [attr.colspan]="14">No records found</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<p-dialog header="Change Rate" [(visible)]="changeRate" [style]="{width: '30vw'}"
    [breakpoints]="{ '991px': '75vw', '575px': '95vw' }">
    <form [formGroup]="cardRate.form">
        <div class="grid">
            <div class="field col-12 md:col-6 lg:col-12">
                <label for="float-input">Charge</label>
                <input id="amount" type="text" maxlength="5" formControlName="amount" aria-describedby="amount-help"
                    pInputText required pAutoFocus [autofocus]="true">
                <f-error [err]="cardRate.pattern('amount')" msg="Only Numbers are accepted"></f-error>
                <f-error [err]="cardRate.required('amount')" msg="Charge is required"></f-error>

            </div>
            <div class="field col-12 md:col-6 lg:col-12" *ngIf="cardRate.get('isPaymentNote')==true">
                <label for="float-input">Payment Note</label>

                <textarea class="p-inputtextarea p-inputtext  p-component p-element" [rows]="2" [cols]="40"
                    maxlength="500" formControlName="paymentDetails" pInputTextarea>
                </textarea>

            </div>
            <div class="field col-12 md:col-6 lg:col-12">
                <p-checkbox [binary]="true" inputId="binary" formControlName="isMyRate">
                </p-checkbox> Update my rate card with the new charges
            </div>
        </div>
        <div class="  text-center">
            <button pButton pRipple type="button" (click)="rateSubmit()" label="Submit"
                class="p-button-raised p-button-success" [disabled]=" sysService.loading">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
            <button pButton pRipple type="button" (click)="cancelRateChange();dt.reset()" label=" Cancel"
                class="p-button-raised p-button-text" [disabled]="sysService.loading">
                <i *ngIf="sysService.loading" class="pi pi-spin pi-spinner"></i>
            </button>
        </div>
    </form>
</p-dialog>