


<div *ngIf="rechargeValue">
  <p-tabView *ngIf="sysService.isAdmin()" [activeIndex]="activeIndex" (onChange)="handleChange($event)">
    <p-tabPanel header="ECSCRIBE REVENUE">
      <app-eclarity-revenue *ngIf="activeIndex === 0 "></app-eclarity-revenue>
    </p-tabPanel>
    <p-tabPanel header="RADIOLOGIST RATES">
      <app-rate-card [rateUpate]="true" *ngIf="activeIndex === 1 "></app-rate-card>
    </p-tabPanel>
    <p-tabPanel header="TRANSACTION & INVOICES ">
      <app-transaction-history *ngIf="activeIndex === 2 "></app-transaction-history>
    </p-tabPanel>
    <p-tabPanel  *ngIf="rechargeValue" header=" WALLET DETAILS ">
      <app-wallet-details *ngIf="activeIndex === 3 "></app-wallet-details>
    </p-tabPanel>
  </p-tabView>
  <!-- -------------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------------- -->
  <!-- for radiologists -->
  <p-tabView *ngIf="sysService.isRad()" [activeIndex]="activeIndex" (onChange)="handleChange($event)">
    <p-tabPanel header="RECHARGE">
      <app-payment-recharge *ngIf="activeIndex ===0"></app-payment-recharge>
    </p-tabPanel>
    <p-tabPanel header="PAYABLES">
      <app-payable *ngIf="activeIndex ===1"></app-payable>
    </p-tabPanel>
    <p-tabPanel header="RECEIVABLES">
      <app-receivable *ngIf="activeIndex ===2"></app-receivable>
    </p-tabPanel>
    <p-tabPanel header="MY RATES">
      <app-rate-card *ngIf="activeIndex ===3"></app-rate-card>
    </p-tabPanel>
    <p-tabPanel header="TRANSACTION & INVOICES ">
      <app-transaction-history *ngIf="activeIndex ===4"></app-transaction-history>
    </p-tabPanel>
    <p-tabPanel *ngIf="rechargeValue" header=" WALLET DETAILS ">
      <app-wallet-details *ngIf="activeIndex ===5"></app-wallet-details>
    </p-tabPanel>
  </p-tabView>
  <!-- ------------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------------
for center  -->
  <p-tabView *ngIf="sysService.isCenter()" [activeIndex]="activeIndex" (onChange)="handleChange($event)">
    <p-tabPanel header="RECHARGE">
      <app-payment-recharge *ngIf="activeIndex ===0"></app-payment-recharge>
    </p-tabPanel>
    <p-tabPanel header="PAYABLES">
      <app-payable *ngIf="activeIndex ===1"></app-payable>
    </p-tabPanel>
    <p-tabPanel header="RADIOLOGIST RATES">
      <app-rate-card [rateUpate]="true" *ngIf="activeIndex ===2"></app-rate-card>
    </p-tabPanel>
    <p-tabPanel header="TRANSACTION & INVOICES ">
      <app-transaction-history *ngIf="activeIndex ===3"></app-transaction-history>
    </p-tabPanel>
    <p-tabPanel *ngIf="rechargeValue"  header=" WALLET DETAILS ">
      <app-wallet-details *ngIf="activeIndex ===4"></app-wallet-details>
    </p-tabPanel>
  </p-tabView>
</div>






<div *ngIf="!rechargeValue">


  <p-tabView *ngIf="sysService.isAdmin()" [activeIndex]="activeIndex" (onChange)="handleChange($event)">
    <p-tabPanel header="ECSCRIBE REVENUE">
      <app-eclarity-revenue *ngIf="activeIndex === 0 "></app-eclarity-revenue>
    </p-tabPanel>
    <p-tabPanel header="RADIOLOGIST RATES">
      <app-rate-card [rateUpate]="true" *ngIf="activeIndex === 1 "></app-rate-card>
    </p-tabPanel>
    <p-tabPanel header="TRANSACTION & INVOICES ">
      <app-transaction-history *ngIf="activeIndex === 2 "></app-transaction-history>
    </p-tabPanel>
    <p-tabPanel  *ngIf="rechargeValue" header=" WALLET DETAILS ">
      <app-wallet-details *ngIf="activeIndex === 3 "></app-wallet-details>
    </p-tabPanel>
  </p-tabView>
  <!-- -------------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------------- -->
  <!-- for radiologists -->
  <p-tabView *ngIf="sysService.isRad()" [activeIndex]="activeIndex" (onChange)="handleChange($event)">
    <!-- <p-tabPanel header="RECHARGE">
    <app-payment-recharge *ngIf="activeIndex ===0"></app-payment-recharge>
  </p-tabPanel> -->
    <p-tabPanel header="PAYABLES">
      <app-payable *ngIf="activeIndex ===0"></app-payable>
    </p-tabPanel>
    <p-tabPanel header="RECEIVABLES">
      <app-receivable *ngIf="activeIndex ===1"></app-receivable>
    </p-tabPanel>
    <p-tabPanel header="MY RATES">
      <app-rate-card *ngIf="activeIndex ===2"></app-rate-card>
    </p-tabPanel>
    <p-tabPanel header="TRANSACTION & INVOICES ">
      <app-transaction-history *ngIf="activeIndex ===3"></app-transaction-history>
    </p-tabPanel>
    <p-tabPanel *ngIf="rechargeValue" header=" WALLET DETAILS ">
      <app-wallet-details *ngIf="activeIndex ===4"></app-wallet-details>
    </p-tabPanel>
  </p-tabView>
  <!-- ------------------------------------------------------------------------------------------------------
------------------------------------------------------------------------------------------------------
for center  -->
  <p-tabView *ngIf="sysService.isCenter()" [activeIndex]="activeIndex" (onChange)="handleChange($event)">

    <p-tabPanel header="PAYABLES">
      <app-payable *ngIf="activeIndex ===0"></app-payable>
    </p-tabPanel>
    <p-tabPanel header="RADIOLOGIST RATES">
      <app-rate-card [rateUpate]="true" *ngIf="activeIndex ===1"></app-rate-card>
    </p-tabPanel>
    <p-tabPanel header="TRANSACTION & INVOICES ">
      <app-transaction-history *ngIf="activeIndex ===2"></app-transaction-history>
    </p-tabPanel>
    <p-tabPanel *ngIf="rechargeValue"  header=" WALLET DETAILS ">
      <app-wallet-details *ngIf="activeIndex ===3"></app-wallet-details>
    </p-tabPanel>
  </p-tabView>
</div>



